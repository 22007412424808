import Link from 'next/link';
import React from 'react';

const FourOhFour = (): JSX.Element => {
  return (
    <div style={{ fontSize: '1.6rem', padding: '20px', textAlign: 'center' }}>
      <h1>Denne siden finnes ikke, eller kan ikke vises.</h1>
      <br />
      <p>
        <Link href="/" style={{ textDecoration: 'underline' }}>
          Trykk her for å gå tilbake til forsiden.
        </Link>
      </p>
      <br />
      <p>
        <small>Dersom du tror dette er en feil, gi oss beskjed.</small>
      </p>
    </div>
  );
};

export default FourOhFour;
